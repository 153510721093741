import React from 'react';
import { Switch, Route } from "react-router-dom";
import BookingDetails from './BookingDetails';
import BookingsList from './BookingsList';
import BookingsCalendar from './BookingsCalendar';
import BookingsScheduler from './BookingsScheduler';
import AvailabilitiesList from './AvailabilitiesList';
import AvailabilityDetails from './AvailabilityDetails';
import Paths from './Paths';


export default function Bookings() {

  return (
    <Switch>

      {/* AVAILABILITES */}
      <Route exact path={Paths.AVAILABILITIES_LIST}>
        <AvailabilitiesList />
      </Route>
      <Route path={`${Paths.AVAILABILITIES}/:id`}>
        <AvailabilityDetails />
      </Route>

      {/* <Route exact path={Paths.PROVIDERS_BOOKINGS}>
        <BookingsList />
      </Route> */}

      {/* RESERVATIONS */}
      <Route exact path={Paths.BOOKINGS_SCHEDULE}>
        <BookingsScheduler />
      </Route>
      <Route exact path={Paths.BOOKINGS_CALENDAR}>
        <BookingsCalendar />
      </Route>
      <Route exact path={Paths.BOOKINGS_LIST}>
        <BookingsList />
      </Route>
      <Route path={`${Paths.BOOKINGS}/:id`}>
        <BookingDetails />
      </Route>
    </Switch>
  );
}