import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Stack } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Prompt } from "react-router-dom";
import ProductOverview from './ProductOverview';
import ProductAttachments from './ProductAttachments';
import Chat from '../core/Chat';
import { NavigationConfirm } from '../core/Navigator';

export default function ProductDetails() {

  const { product, dirty } = useStoreState((state) => state.products);
  const { fetchProduct, updateProduct, clearProduct, confirmProduct, loadProducts, saveProductAttachments } = useStoreActions((state) => state.products);

  const history = useHistory();
  const params = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))

  const [chatExpanded, setChatExpanded] = useState(true);
  const onChatExpanded = (exp) => setChatExpanded(exp);
  const onChat = () => { setChatExpanded(true); }

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirm = async () => {
    await onConfirm();
    handleConfirmDialogClose();
  };

  useEffect(() => {
    async function load() {
      await fetchProduct(params.id);
    }
    load();
    return () => {
      clearProduct();
    }
  }, []);

  const onBack = () => {
    history.goBack();
  }

  const onFilesChange = (files) => {
    updateProduct({ ...product, attachments: files });
  }

  const onSave = async () => {
    await saveProductAttachments();
    await loadProducts({ force: true });
  }

  const onConfirm = async () => {
    const productId = product?.data?.productId;
    if (productId) {
      await confirmProduct(productId);
      await loadProducts({ force: true});
      onBack();
    }
  }


  const status = product?.status;

  return (
    <Box display="flex" flexDirection="column" sx={{ flex: 1, p: 2, pt: 0 }}>

      <NavigationConfirm condition={dirty} message="Tienes cambios sin guardar. Si sales perdarás lo que has editado ¿Estás seguro de salir?" buttonConfirm="Salir sin guardar" buttonCancel="Continuar editando" navigationType="push" />

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center" sx={{ height: '58px', mb: 1 }}>
          <IconButton size="medium" onClick={onBack}><ArrowBack /></IconButton>
          {!isMobile && <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'bold' }}>{product?.data?.name}</Typography>}
        </Stack>

        {!!product && <Stack direction="row" spacing={2} alignItems="center">
          {status === 'PROPOSAL' && <>
            <Button variant="outlined" size="small" color="success" disabled={dirty} onClick={() => {}}>Enviar a revisión</Button>
            <Divider orientation="vertical" flexItem />
          </>}
          {status !== 'PRODUCT' && status !== 'VALIDATED' && <Button variant='outlined' color="error" size="small" onClick={() => {}}>Eliminar</Button>}
          {dirty && <Button variant="contained" size="small" onClick={onSave}>Guardar</Button>}
          {status === 'VALIDATED' && <>
            {/* <Button variant="outlined" color="error" size="small" onClick={() => {}}>Rechazar</Button> */}
            <Button variant="contained" color="success" size="small" onClick={handleConfirmDialogOpen}>Confirmar</Button>
          </>}
        </Stack>}
      </Box>

      <Box display="flex" gap={2} sx={{ overflowY: 'hidden', height: `calc(100vh - 160px)` }}>
   
        <Stack flex={3} spacing={2} sx={{ overflowX: 'hidden', overflowY: 'scroll', width: '100%' }}>
          <Box id="details">
            <ProductOverview product={product} />
          </Box>
          <Box id="payment" sx={{ }}>
            <ProductAttachments files={product?.attachments} onFilesChange={onFilesChange} />
          </Box>
        </Stack>

        <Box id="chat" flex={2} sx={{  }}>
          <Chat context="products" entity={params.id} provider={product?.providerId} expand={chatExpanded} onExpand={onChatExpanded} />
        </Box>

      </Box>
     
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            ¿Estás seguro de que deseas confirmar este producto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box> 
  );
}

