import { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";
import { Close } from '@mui/icons-material';
import { store } from '../../../store';
import { get, post, remove } from '../../../http';
import { useSnackbar } from 'notistack';
import { Button, IconButton } from '@mui/material';
import { useHistory } from "react-router-dom";
import SnackMessage from '../Notification';
import { useStore, useStoreActions, useStoreState } from 'easy-peasy';

const EventEmitter = require('events');

const vapidKey = "BLxAZoef1yQtSgon6PyLKX61h-ixC3x4ZuRgybwOkLyAGO6uTpL_4QIXg81BrJmkFwM2m-ihpPzbLgEDIO-RKEk";
const config = {
  apiKey: "AIzaSyBiJbnbMpKZfAuB3aUYmTkFt2FvhTxWgCI",
  authDomain: "oyde-dev.firebaseapp.com",
  projectId: "oyde-dev",
  // storageBucket: "oyde-dev.appspot.com",
  storageBucket: "oyde-dev.firebasestorage.app",
  messagingSenderId: "587083697079",
  appId: "1:587083697079:web:4c492aae4a85dcdb01b632"
};

let messaging;


async function setNotificationsToken(token) {
  const prevToken = store.getState().auth.notificationsToken;

  const setNotificationsToken = store.getActions().auth.setNotificationsToken;
  setNotificationsToken(token);

  await store.persist.flush();
  return prevToken;
}

function hasSession() {
  return store.getState().auth.loggedIn;
}



const notifier = new EventEmitter();

export default function useNotifications() {

  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // const action = key => (
  //   <>
  //     <Button onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
  //         Ver
  //     </Button>
  //     <IconButton sx={{ color: 'white' }} onClick={() => closeSnackbar()}><Close /></IconButton>
  //   </>
  // );

  // const startup = async () => {}
  // const bindToken = async () => {}
  // const unbindToken = async () => {}

  const startup = async () => {
    const app = initializeApp(config);
    messaging = getMessaging(app);

    onMessage(messaging, (payload) => {
      console.log('MESSAGE:', payload);

      if (payload.fcmOptions.link) {

        enqueueSnackbar(payload.notification.title, {
          variant: 'info',
          autoHideDuration: 6000,
          // action: (key) => (
          //   <>
          //     <Button onClick={() => { console.log('clicked notification', payload.data?.link); history.push(payload.data?.link || payload.fcmOptions.link); }}>
          //         Ver
          //     </Button>
          //     <IconButton sx={{ color: 'white' }} onClick={() => closeSnackbar()}><Close /></IconButton>
          //   </>
          // ),
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          // content: (key, message) => (
          //   <SnackMessage id={key} title={payload.notification.title} message={payload.notification.body} link={payload.fcmOptions.link} />
          // )
        });
      }

      if (payload.data?.code) {
        notifier.emit(payload.data.code, payload.data);
        notifier.emit('change', {});
      }
    });

    const fetchNotifiations = store.getActions().notifications.fetchNotifications;

    await Promise.all([
      fetchNotifiations(),
      bindToken(),
    ]);
  }

  const bindToken = async () => {
    try {
      const token = await getToken(messaging, { vapidKey });
      if (token) {
        const prevToken = await setNotificationsToken(token);
        if (hasSession()) {
          // console.log('NOTIF TOKEN:', token);
          await post("/notifications/token", { token });
          if (prevToken && prevToken !== token) {
            await remove(`/notifications/token?key=${prevToken}`);
          }
        }
      }
    }
    catch(err) {
      console.log(err.message);
      if (err?.code === 'messaging/permission-blocked') {
        // console.log("request permission!");
        // enqueueSnackbar("REQUEST PERMISSIONS");
      }
      
    }
  }

  const unbindToken = async () => {
    try {
      const succeeded = await deleteToken(messaging);
      if (succeeded) {
        const prevToken = await setNotificationsToken(null);
        if (hasSession()) {
          await remove(`/notifications/token?key=${prevToken}`);
        }
      }
    }
    catch(err) {
      console.log(err);
    }
  }

  return { startup, bindToken, unbindToken, notifier };
}

export const useMarkAsRead = ({ type, context, entity, provider }) => {

  const { markNotificationAsRead } = useStoreActions(actions => actions.notifications);
  const { notifications } = useStoreState(state => state.notifications);

  useEffect(() => {
      if (context && entity && provider) {

        const unreadNotificaton = notifications.find(n => n.type === type && n.context === context && n.entity === Number(entity) && !n.read);
        if (unreadNotificaton) {
          markNotificationAsRead(unreadNotificaton._id);
        }
        // markNotificationAsRead({ context, subcontext, entity, provider });
      }
    }, [context, entity, provider]);
}

// export const useMarkAsRead = () => {

//   const location = useLocation();
//   const { markNotificationAsRead } = useStoreActions(actions => actions.notifications);

//   useEffect(() => {
//       const notification = location?.state?.notification;
//       console.log('NOTIFICATION:', notification);
//       if (notification && !notification.read) {

//         markNotificationAsRead(notification);
//       }
//     }, [location]);
// }