import React from 'react';
import { Grid, Typography, Chip, Box, Tooltip, Button, Card, CardActionArea, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccessTime, MailOutlined, InfoOutlined, ChatBubbleOutline, FlightOutlined, PaymentOutlined as PaymentIcon, AccountCircle, CalendarMonthOutlined as Schedule, DraftsOutlined } from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import { formatWithOptions, isSameYear, parseISO } from 'date-fns/fp';
import { es } from 'date-fns/locale';


const dateWithoutYearFormatter = formatWithOptions({ locale: es }, 'd LLLL');
const dateWithYearFormatter = formatWithOptions({ locale: es }, 'd LLLL yyyy');
const dayOfWeekFormatter = formatWithOptions({ locale: es }, 'EEEE');
const timeFormatter = formatWithOptions({ locale: es }, 'HH:mm');

export default function MessageCard({notification, lite}) {

  const history = useHistory();
  const classes = useStyles();

  const timestamp = parseISO(notification.timestamp);
  const dateFormatter = isSameYear(timestamp, new Date()) ? dateWithoutYearFormatter : dateWithYearFormatter;
  const dateComponent = <span style={{ fontSize: '12px' }}>{dateFormatter(timestamp)}</span>;
  // const dateComponent = (<span>{dateFormatter(timestamp)} - <span style={{ fontWeight: 'bold', color: 'gray' }}>{dayOfWeekFormatter(timestamp)}</span></span>);
  const timeComponent = (<span style={{ fontSize: '12px' }}><strong>{timeFormatter(timestamp)}</strong></span>);

  const onOpen = () => {
    history.push(notification.link, { notification });
  };

  return (
    <Grid item xs={12} lg={12}>
      <Card className={classes.item} elevation={2} style={{ border: '1px solid #DBD7D2' /*, borderLeftColor: notification.read ? '#ACD0E7' : '#165FC7' */ }}>
        <CardActionArea onClick={_ => onOpen()}>

          <div className={classes.card}>

            <Box display="flex" flexDirection="column" justifyContent="flex-start" gap={0} sx={{ width: '100%', p: 0, pl: 1 }}>

              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ }}>
                <Stack direction="row" gap={1} alignItems="center" sx={{ maxWidth: '100%', ml: 0 }}>
                  {/* {notification.type === "MESSAGE" && <MailOutlined color="primary" />}
                  {notification.type === "EVENT" && <DraftsOutlined color="primary" />} */}
                  {/* {!notification.read ? <MailOutlined color="primary" /> : <DraftsOutlined color="primary" />} */}
                  <Typography variant="caption" color="CaptionText" sx={{ ml: 0, mb: 0, mt: 0, fontSize: 12, fontWeight: notification.read ? 'normal' : 'bold' }}>{notification.title.toUpperCase()}</Typography>
                </Stack>

                <div style={{ display: 'flex' }}>
                  <Chip style={{ margin: 0, height: '20px', backgroundColor: 'white' }}
                    variant="default" 
                    size="small"
                    // icon={<AccessTime />}
                    label={<Stack direction="row" gap={1}>{dateComponent}{timeComponent}</Stack>}
                  />
                </div>
              </Box>
              
              <Typography variant="caption" sx={{ ml: 0, mb: 0, mt: 0, fontWeight: notification.read ? 'normal' : 'normal', color: 'gray' }}>{notification.text}</Typography>
            </Box>
          </div>

        </ CardActionArea>
      </Card>
    </Grid>
  );
};


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer',
    padding: '8px', 
    transition: 'all .20s linear',
    boxSshadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
    '&:hover' : {
      boxShadow: '-1px 1px 9px 0px rgba(0,0,0,0.4)',
    },
    // '&$itemHeader': {
    //   '&:hover' : {
    //     backgroundColor: '#dfe7fd',
    //   },
    // },
  },
  itemHeader: {
    backgroundColor: '#F5F5F5', 
    borderRadius: '5px 5px 0px 0px',
    borderBottom: '1px solid white',

    transition: 'all .20s linear',
    '&:hover' : {
      backgroundColor: 'yellow', // faf3dd
      // backgroundColor: '#faf3dd',
    },
  },
  link: {
    marginRight: '10px',
    // color: 'darkorange',
  },
  logo: {
    height: '34px',
    margin: '10px',
  },

  card: {
    display: 'flex',
  },
  cardLine: {
    width: '8px',
    // backgroundColor: '#E84C3D',
    backgroundColor: 'lightgray',
    borderRadius: '2px',
  },
  cardContent: {
    width: '100%',
    padding: 0, //padding: '0 0 0 8px',
    // paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  label: {
    // borderRadius: '3px',
    border: '0px',
    marginRight: '8px',
    // color: '#5B5B5B',
    maxWidth: '100%',
  },
  tag: {
    borderRadius: '3px',
    // border: '0px',
    marginRight: '8px',
    color: '#5B5B5B',
  }
}));















// import React from 'react';
// import { Grid, Typography, Chip, Box, Tooltip, Button, Card, CardActionArea, Stack } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { AccessTime, MailOutlined, InfoOutlined, ChatBubbleOutline, FlightOutlined, PaymentOutlined as PaymentIcon, AccountCircle, CalendarMonthOutlined as Schedule, DraftsOutlined } from '@mui/icons-material';
// import { useHistory } from "react-router-dom";
// import { formatWithOptions, isSameYear, parseISO } from 'date-fns/fp';
// import { es } from 'date-fns/locale';


// const dateWithoutYearFormatter = formatWithOptions({ locale: es }, 'd LLLL');
// const dateWithYearFormatter = formatWithOptions({ locale: es }, 'd LLLL yyyy');
// const dayOfWeekFormatter = formatWithOptions({ locale: es }, 'EEEE');
// const timeFormatter = formatWithOptions({ locale: es }, 'HH:mm');

// export default function MessageCard({notification, lite}) {

//   const history = useHistory();
//   const classes = useStyles();

//   const timestamp = parseISO(notification.timestamp);
//   const dateFormatter = isSameYear(timestamp, new Date()) ? dateWithoutYearFormatter : dateWithYearFormatter;
//   const dateComponent = (<span>{dateFormatter(timestamp)} - <span style={{ fontWeight: 'bold', color: 'gray' }}>{dayOfWeekFormatter(timestamp)}</span></span>);
//   const timeComponent = (<span><strong>{timeFormatter(timestamp)}</strong></span>);

//   const onOpen = () => {
//     history.push(notification.link, { notification });
//   };

//   return (
//     <Grid item xs={12} lg={12}>
//       <Card className={classes.item} elevation={2} style={{ borderLeft: '8px solid gray', borderLeftColor: notification.read ? '#ACD0E7' : '#165FC7' }}>
//         <CardActionArea onClick={_ => onOpen()}>

//           <div className={classes.card}>

//             <Box display="flex" flexDirection="column" justifyContent="flex-start" gap={1} sx={{ width: '100%', p: 1 }}>

//               <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ }}>
//                 <Stack direction="row" gap={1} alignItems="center" sx={{ maxWidth: '100%', ml: 0 }}>
//                   {/* {notification.type === "MESSAGE" && <MailOutlined color="primary" />}
//                   {notification.type === "EVENT" && <DraftsOutlined color="primary" />} */}
//                   {!notification.read ? <MailOutlined color="primary" /> : <DraftsOutlined color="primary" />}
//                   <Typography variant="subtitle1" color="primary" style={{ marginLeft: '8px', fontWeight: notification.read ? 'normal' : 'bold' }}>{notification.title}</Typography>
//                 </Stack>

//                 <div style={{ display: 'flex' }}>
//                   <Chip style={{ margin: '10px 10px 0px 5px' }}
//                     variant="default" 
//                     size="small"
//                     icon={<AccessTime />}
//                     label={<Stack direction="row" gap={2}>{timeComponent}{dateComponent}</Stack>}
//                   />
//                 </div>
//               </Box>
              
//               <Typography variant="body2" sx={{ ml: 5, fontWeight: notification.read ? 'normal' : 'bold' }}>{notification.text}</Typography>
//             </Box>
//           </div>

//         </ CardActionArea>
//       </Card>
//     </Grid>
//   );
// };


// const useStylesBootstrap = makeStyles((theme) => ({
//   arrow: {
//     color: theme.palette.common.black,
//   },
//   tooltip: {
//     backgroundColor: theme.palette.common.black,
//   },
// }));

// function BootstrapTooltip(props) {
//   const classes = useStylesBootstrap();

//   return <Tooltip arrow classes={classes} {...props} />;
// }

// const useStyles = makeStyles((theme) => ({
//   item: {
//     cursor: 'pointer',
//     padding: '8px', 
//     transition: 'all .20s linear',
//     boxSshadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
//     '&:hover' : {
//       boxShadow: '-1px 1px 9px 0px rgba(0,0,0,0.4)',
//     },
//     // '&$itemHeader': {
//     //   '&:hover' : {
//     //     backgroundColor: '#dfe7fd',
//     //   },
//     // },
//   },
//   itemHeader: {
//     backgroundColor: '#F5F5F5', 
//     borderRadius: '5px 5px 0px 0px',
//     borderBottom: '1px solid white',

//     transition: 'all .20s linear',
//     '&:hover' : {
//       backgroundColor: '#dfe7fd', // faf3dd
//       // backgroundColor: '#faf3dd',
//     },
//   },
//   link: {
//     marginRight: '10px',
//     // color: 'darkorange',
//   },
//   logo: {
//     height: '34px',
//     margin: '10px',
//   },

//   card: {
//     display: 'flex',
//   },
//   cardLine: {
//     width: '8px',
//     // backgroundColor: '#E84C3D',
//     backgroundColor: 'lightgray',
//     borderRadius: '2px',
//   },
//   cardContent: {
//     width: '100%',
//     padding: 0, //padding: '0 0 0 8px',
//     // paddingBottom: 0,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'flex-start',
//   },
//   label: {
//     // borderRadius: '3px',
//     border: '0px',
//     marginRight: '8px',
//     // color: '#5B5B5B',
//     maxWidth: '100%',
//   },
//   tag: {
//     borderRadius: '3px',
//     // border: '0px',
//     marginRight: '8px',
//     color: '#5B5B5B',
//   }
// }));