import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { MenuRounded, EventAvailable, Warning, Payment, Settings, AssignmentInd, Home, MailOutlined, Chat, Gavel, Tune, BugReport, Diversity3, ImportContacts, PendingActions } from '@mui/icons-material';
import { Sidebar, Menu, SubMenu, MenuItem, sidebarClasses } from 'react-pro-sidebar';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory, useLocation, Link } from "react-router-dom";
import { Box, Typography, Badge, Tooltip } from '@mui/material';
import logo from '../core/images/oyde-logo.png';
import Paths from './Paths';


// const Paths = {
//   HOME: "/providers",
//   BOOKINGS: "/providers/bookings",
//   PAYMENTS: "/providers/payments",
//   REGISTRIES: "/providers/registry",
// }

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // maxWidth: 360,
    minWidth: 180,
    marginTop: '0px',
    marginRight: '8px',
    backgroundColor: '#FAFAFA', //theme.palette.background.paper,
  },
}));



export default function ProvidersMenu() {
  const classes = useStyles();
  const history = useHistory();
  
  const { reservationNotifications, availabilityNotifications } = useStoreState(state => state.notifications);

  const {collapsed } = useStoreState(state => state.menu);
  const [currentPath, setCurrentPath] = useState(history.location.pathname);

  const isPath = (targetPath) => {
    return currentPath.startsWith(targetPath)
  }

  const isExactPath = (targetPath) => {
    return currentPath === targetPath;
  }

  // Subscripción a eventos del historial para controlar la ruta actual.
  useEffect(() => {

    const unsubscribe = history.listen((location, action) => {
      setCurrentPath(location.pathname);
    });
    return function cleanup() {
      unsubscribe();
    };
  }, []);

  // const handleListItemClick = (event, targetPath) => {

  //   toggleMenu();
  //   setCurrentPath(targetPath);
  //   history.push(targetPath);
  // };

  return (
    <Sidebar collapsed={collapsed}
      collapsedWidth='76px'
      width='230px'
      style={{ paddingTop: 0, height: '90vh' }}
      
      

      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          // backgroundColor: '#353535',
          backgroundColor: 'white',
          padding: '0px',
          // paddingTop: '5px',
          fontSize: '14px',
          height: '100vh',
        },
    }}>

        <Box display="flex" justifyContent="start" alignItems="center" height={64} boxShadow={0} sx={{ pl: 2, mb: 2, backgroundColor: '#1876D2' }}>
          
            <img src={logo} style={{ height: '42px' }} />

            {!collapsed && <Typography variant="body1" display="block" color="white" sx={{ ml: 1, fontWeight: 'normal' }}>
              Proveedores
            </Typography>}
         
        </Box>

        <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0) {

            }
            // only apply styles on first level elements of the tree
            // if (level === 0)
              return {
                color: disabled ? '#D2D2D2' : (active ? '#424242' : '#818081'),
                backgroundColor: active ? '#B9D9EB' : undefined,
                borderRadius: '5px',
                height: '40px',
                padding: '10px',
                marginRight: '10px',
                marginLeft: '10px',
                marginBottom: '3px',
                '&:hover': {
                  backgroundColor: !active ? '#F2F2F2' : '#B9D9EB',
                  opacity: !active ? 1 : 0.8,
                }
              };
          },
          // icon: ({ level, active, disabled }) => {
          //   return {
          //     fill: 'red', 
          //     height: '24px',
          //   };
          // },
        }}>
          <MenuItem
            active={isExactPath(Paths.HOME)}
            component={<Link to={Paths.HOME} />}
            // className="menu1"
            icon={<Home />}
          >
            Inicio
          </MenuItem>

          <MenuItem
            active={isPath(Paths.AVAILABILITIES)}
            component={<Link to={Paths.AVAILABILITIES_LIST} className="link" />}
            icon={<PendingActions />}
            suffix={<Badge badgeContent={availabilityNotifications.length} color="info" />}
          >
            Disponibilidad
          </MenuItem>

          <MenuItem
            active={isPath(Paths.BOOKINGS)}
            component={<Link to={Paths.BOOKINGS_LIST} className="link" />}
            icon={<EventAvailable />}
            suffix={<Badge badgeContent={reservationNotifications.length} color="info" />}
          >
            Reservas
          </MenuItem>

          <MenuItem
            active={isPath(Paths.PAYMENTS)}
            component={<Link to={Paths.PAYMENTS} className="link" />}
            icon={<Payment />}
          >
            Pagos
          </MenuItem>

          <MenuItem
            active={isPath(Paths.ISSUES)}
            component={<Link to={Paths.ISSUES} className="link" />}
            icon={<BugReport />}
            disabled
          >
            Incidencias
          </MenuItem>

          {/* <MenuItem
            active={isPath(Paths.MESSAGES)}
            component={<Link to={Paths.MESSAGES} className="link" />}
            icon={<MailOutlined />}
            suffix={<Badge badgeContent={unreadNotifications.length} color="info" />}
          >
            Mensajes
          </MenuItem> */}

          <MenuItem
            active={isPath(Paths.REGISTRIES)}
            component={<Link to={Paths.REGISTRIES} className="link" />}
            icon={<AssignmentInd />}
          >
            Datos proveedor
          </MenuItem>

          <MenuItem
            active={isPath(Paths.PRODUCTS)}
            component={<Link to={Paths.PRODUCTS_LIST} className="link" />}
            icon={<ImportContacts />}
          >
            Productos
          </MenuItem>

          <MenuItem
            active={isPath(Paths.CONTRACTS)}
            component={<Link to={Paths.CONTRACTS} className="link" />}
            icon={<Gavel />}
            disabled
          >
            Contratos
          </MenuItem>

          <MenuItem
            active={isPath(Paths.TEAM)}
            component={<Link to={Paths.TEAM} className="link" />}
            icon={<Diversity3 />}
            disabled
          >
            Equipo
          </MenuItem>

          {/* <MenuItem
            active={isPath(Paths.SETTINGS)}
            component={<Link to={Paths.SETTINGS} className="link" />}
            icon={<Tune />}
          >
            Configuración
          </MenuItem> */}

          

          {/* <MenuItem icon={<ReceiptRoundedIcon />}> Invoices </MenuItem>
          <SubMenu label="Charts" icon={<BarChartRoundedIcon />}>
            <MenuItem icon={<TimelineRoundedIcon />}> Timeline Chart </MenuItem>
            <MenuItem icon={<BubbleChartRoundedIcon />}>Bubble Chart</MenuItem>
          </SubMenu>
          <SubMenu label="Wallets" icon={<WalletRoundedIcon />}>
            <MenuItem icon={<AccountBalanceRoundedIcon />}>
              Current Wallet
            </MenuItem>
            <MenuItem icon={<SavingsRoundedIcon />}>Savings Wallet</MenuItem>
          </SubMenu>
          <MenuItem
            component={<Link to="transactions" className="link" />}
            icon={<MonetizationOnRoundedIcon />}
          >
            Transactions
          </MenuItem>
          <SubMenu label="Settings" icon={<SettingsApplicationsRoundedIcon />}>
            <MenuItem icon={<AccountCircleRoundedIcon />}> Account </MenuItem>
            <MenuItem icon={<ShieldRoundedIcon />}> Privacy </MenuItem>
            <MenuItem icon={<NotificationsRoundedIcon />}>
              Notifications
            </MenuItem>
          </SubMenu>
          <MenuItem icon={<LogoutRoundedIcon />}> Logout </MenuItem> */}
        </Menu>
      </Sidebar>
    // <div className={classes.root}>

    //   <List component="nav" aria-label="main mailbox folders" dense={true}
    //     subheader={
    //       <ListSubheader component="div" id="nested-list-subheader">
    //         MENU PRINCIPAL
    //       </ListSubheader>
    //     }
    //   >
        
    //     <ListItem button selected={isExactPath(Paths.HOME)} onClick={(event) => handleListItemClick(event, Paths.HOME)}>
    //       <ListItemIcon>
    //         <Home />
    //       </ListItemIcon>
    //       <ListItemText primary="Inicio" />
    //     </ListItem>

    //     <ListItem button selected={isPath(Paths.REGISTRIES)} onClick={(event) => handleListItemClick(event, Paths.REGISTRIES)}>
    //       <ListItemIcon>
    //         <AssignmentInd />
    //       </ListItemIcon>
    //       <ListItemText primary="Registro" />
    //     </ListItem>

    //     <ListItem button selected={isPath(Paths.BOOKINGS)} onClick={(event) => handleListItemClick(event, Paths.BOOKINGS)}>
    //       <ListItemIcon>
    //         <EventAvailable />
    //       </ListItemIcon>
    //       <ListItemText primary="Reservas" />
    //     </ListItem>

    //     <ListItem button selected={isPath(Paths.PAYMENTS)} onClick={(event) => handleListItemClick(event, Paths.PAYMENTS)}>
    //       <ListItemIcon>
    //         <Payment />
    //       </ListItemIcon>
    //       <ListItemText primary="Pagos" />
    //     </ListItem>

    //     <ListItem button selected={false} onClick={(event) => handleListItemClick(event, Paths.BOOKINGS)} disabled>
    //       <ListItemIcon>
    //         <Warning />
    //       </ListItemIcon>
    //       <ListItemText primary="Incidencias" />
    //     </ListItem>

    //     <ListItem button selected={false} onClick={(event) => handleListItemClick(event, Paths.BOOKINGS)} disabled>
    //       <ListItemIcon>
    //         <Settings />
    //       </ListItemIcon>
    //       <ListItemText primary="Configuración" />
    //     </ListItem>

    //   </List>
    //   <Divider />
    // </div>
  );
}



// function OydeMenuItem({ id, icon, title, notifications }) {

//   const collapse = useStoreActions((actions) => actions.menu.collapse);
//   const location = useLocation();

//   return (
//     <MenuItem active={location.pathname.startsWith(id)} 
//       component={<Link to={`${id}`}/>}
//       icon={
//         <>
//         {!collapse ? icon : (
//           <Tooltip title={title}>{icon}</Tooltip>
//         )}
//         </>
//       }
//       suffix={notifications ? (<Badge />) : undefined}
//     > 
//       {title}
//     </MenuItem>
//   );
// }