import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Button, IconButton, TextField, InputLabel, Select, MenuItem, Tooltip, Typography, CircularProgress, FormControl, Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { Cancel, Tune } from '@mui/icons-material';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatWithOptions } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import { PAYMENT_STATUS_LIST } from './PaymentStatus';
import Empty from '../core/Empty';

// const TRANSACTION_TYPES = ["Coste servicio", "Anticipado", "Pagado en el sitio", "Pagado por el cliente", "Liquidado"];
// const PAYMENT_STATUSES = ["Pendiente", "Rechazado", "Pagado"];
const dateFormatter = formatWithOptions({ locale: es }, 'd LLL yyyy');
const euroFormatter = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format;

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
    // minWidth: 120,
  },
  row: {
    cursor: 'pointer',
  },
  head: {
    backgroundColor: '#F5F5F5',
  },
  headCell: {
    color: 'white',
    // fontSize: 'bold',
  },
  contentArea: {
    // xs={12} sm={6} md={6} lg={2}
    overflow: 'scroll',
    [theme.breakpoints.only('xs')]: {
      height: 'calc(100vh - 120px)', 
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'calc(100vh - 290px)',
    },
    [theme.breakpoints.only('lg')]: {
      height: 'calc(100vh - 170px)',
    },
  },
}));

export default function Payments() {

  const classes = useStyles();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const [showFilter, setShowFilter] = useState(false);

  const { payments, paymentsFilter: filter } = useStoreState((state) => state.bookings);
  const { fetchPayments, setPaymentsFilter, resetPaymentsFilter } = useStoreActions((state) => state.bookings);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => { enableFilters() }, []); 
  useEffect(() => { if (filter.enabled) onSearch(); }, [filter]);

  const onSearch = async () => {
    setLoading(true);
    await fetchPayments({ filter });
    setLoading(false);
  }

  const enableFilters = () => {
    setPaymentsFilter({ ...filter, enabled: true });
  }

  const onRowSelected = (event, item, index) => {
    // setSelectedIndex(index);
    history.push(`/providers/bookings/${item.bookingId}?show=payments`);
  }

  const initializeFilters = () => {
    resetPaymentsFilter();
  }

  return (
    <Grid container direction="column" style={{ }}>
      
      <Grid item container direction="row" justify="space-between" alignItems='center' style={{ marginBottom: '10px' }}>

        <Grid item>
          {isMobile && !showFilter && <Button variant="text" color="primary" startIcon={<Tune />} onClick={_ => setShowFilter(true)}>Filtros</Button>}
          {isMobile && showFilter && <Button variant="text" color="secondary" onClick={_ => setShowFilter(false)}>Ocultar filtros</Button>}
        </Grid>

        {/* <Grid item>
          {isMobile && totalBookings > 0 && <Pagination page={filter.page} count={Math.ceil(totalBookings / PAGE_SIZE)} onChange={(e, page) => setBookingsFilter({ ...filter, page })} shape="rounded" />}
        </Grid> */}
      </Grid>

      {((showFilter && isMobile) || !isMobile) && <Grid item container justify="flex-start" alignItems="flex-end" spacing={2} style={{ padding: '0 0 24px 16px' }}>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <FormControl className={classes.formControl}>
            <InputLabel id="booking-filter-status-label">Estado</InputLabel>
            <Select
              labelId="booking-filter-status-label"
              id="booking-filter-status"
              placeholder="Selecciona..."
              value={filter.status}
              onChange={e => setPaymentsFilter({ ...filter, status: e.target.value, page: 1 })}
            >
              {PAYMENT_STATUS_LIST.map((v, i) => (<MenuItem key={i} value={v.id}>{v.name}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextField
            label="Fecha Inicio"
            type="date"
            fullWidth={true}
            value={filter.fromDate}
            onChange={e => setPaymentsFilter({ ...filter, fromDate: e.target.value, page: 1 })}
            // className={classes.textField}
            placeholder={"fecha"}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextField
            label="Fecha Final"
            type="date"
            fullWidth={true}
            value={filter.toDate}
            onChange={e => setPaymentsFilter({ ...filter, toDate: e.target.value, page: 1 })}
            // className={classes.textField}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item>
          <Tooltip title="Quitar filtros">
            {/* <Button startIcon={<Clear />} variant="text" onClick={resetPaymentsFilter}>Limpiar filtro</Button> */}
            <IconButton size="small" color="default" onClick={initializeFilters}><Cancel /></IconButton>
          </Tooltip>
        </Grid>
        
      </Grid>}
    
      {payments.length > 0 && !loading && <Grid item container justify="center" className={classes.contentArea} sx={{ pl: 2, pr: 2 }}>
        <TableContainer component={Box}>
          <Table aria-label="simple table" size="medium">
          <TableHead className={classes.head}>
              <TableRow>
                {isMobile ? 
                  <>
                    <TableCell key="srv" className={classes.headCell}>SERVICIO</TableCell>
                    <TableCell key="amn" className={classes.headCell} align="right">PENDIENTE</TableCell>
                  </> :
                  <>
                    <TableCell key="dat" className={classes.headCell}>FECHA</TableCell>
                    <TableCell key="srv" className={classes.headCell}>SERVICIO</TableCell>
                    <TableCell key="cli" className={classes.headCell}>CLIENTE</TableCell>
                    <TableCell key="amn" className={classes.headCell} align="right">PENDIENTE</TableCell>
                  </>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((t, idx) => (
                <TableRow key={idx} className={classes.row} hover selected={idx === selectedIndex} onClick={(event) => onRowSelected(event, t, idx)}>
                  {isMobile ? 
                    <>
                      <TableCell key="tsrv" component="th" scope="row">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div style={{ fontWeight: 'bold' }}>{t.serviceName}</div>
                          <div>{t.client}</div>
                          <div style={{ color: 'gray' }}>{dateFormatter(new Date(t.transactionDate))}</div>
                        </div>
                      </TableCell>
                      <TableCell key="tamn" component="th" scope="row" align="right" style={{  }}>{euroFormatter(t.amount)}</TableCell>
                  </> :
                  <>
                    <TableCell key="tdat" component="th" scope="row">{dateFormatter(new Date(t.transactionDate))}</TableCell>
                    <TableCell key="tsrv" component="th" scope="row" style={{ fontWeight: 'bold' }}>{t.serviceName}</TableCell>
                    <TableCell key="tcli" component="th" scope="row">{t.client}</TableCell>
                    <TableCell key="tamn" component="th" scope="row" align="right" style={{  }}>{euroFormatter(t.amount)}</TableCell>
                  </>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>}

      {payments.length == 0 && !loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        {!loading && <Empty title="No se han encontrado ítems" subtitle="Prueba a cambiar el filtro para obtener otros resultados" />}
      </Grid>}

      {loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        <CircularProgress color="inherit" />
      </Grid>}

    </Grid>
  );
}