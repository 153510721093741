import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Typography, useMediaQuery, Stepper, Step, StepLabel, StepContent, LinearProgress, Button, Divider, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { ListAlt as DetailsIcon, LabelOutlined, AccessTime } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { useParams, useHistory } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import Box from '@mui/material/Box';
import Section from '../core/Section';
import { Title, FormItem } from '../core/Forms';
import { NavigationConfirm } from '../core/Navigator';
import ProposalEditor from './ProposalEditor';
import ProductAttachments from './ProductAttachments';
import Chat from '../core/Chat';
import productService from "./services/ProductService";


export default function ProductProposal() {

  const theme = useTheme();
  const history = useHistory();
  const params = useParams();

  const { product, dirty, loading } = useStoreState((state) => state.products);
  const { fetchProposal, clearProduct, updateProduct, saveProposal, removeProposal, reviewProposal, loadProducts } = useStoreActions((state) => state.products);

  const [validations, setValidations] = React.useState([]);
  const [chatExpanded, setChatExpanded] = useState(true);
  const onChatExpanded = (exp) => setChatExpanded(exp);

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const lite = true;
  const readonly = product?.status !== 'PROPOSAL';

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmReviewOpen, setConfirmReviewOpen] = useState(false);

  const handleDeleteConfirm = () => {
    setConfirmDeleteOpen(true);
  };

  const handleDeleteCancel = () => {
    setConfirmDeleteOpen(false);
  };

  const handleReviewConfirm = () => {
    setConfirmReviewOpen(true);
  };

  const handleReviewCancel = () => {
    setConfirmReviewOpen(false);
  };

  const onReviewConfirmed = async () => {
    setConfirmReviewOpen(false);
    await onReview();
  };

  useEffect(() => {
    async function load() {
      // if (params.id !== 'new') {
        await fetchProposal(params.id);
      // }
    }
    if (params.id) {
      // Load product details
      load();
    }
    return () => {
      clearProduct();
    }
  }, []);

  // const loadProduct = (id) => {
  //   console.log('load product details for id:', id);
  // }

  const onBack = () => {
    history.goBack();
  }

  const onFilesChange = (files) => {
    updateProduct({ ...product, attachments: files });
  }

  const onSave = async () => {
    if (product?.proposal?.name === '') {
      setValidations(['El nombre de la propuesta no puede estar vacío']);
    }
    else {
      await saveProposal();
      await loadProducts({ force: true });
    }
  }

  const onRemove = async () => {
    setConfirmDeleteOpen(false);

    await removeProposal(product?.proposal?.proposalId);
    await loadProducts({ force: true });
    onBack();
  }

  const onReview = async () => {
    await reviewProposal(product?.proposal?.proposalId);
    await loadProducts({ force: true });
    onBack();
  }

  const onConfirmBack = async () => {
    if (product) {
      await productService.organizeUnsavedFiles(product.proposal?.proposalId, product.attachments);
    }
  }

  // const [activeStep, setActiveStep] = React.useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const status = product?.status;
  const isNew = product?.proposal?.ui?.new;

  const canReview = product?.status === 'PROPOSAL' && !dirty && !!product?.proposal?.name && !!product?.proposal?.duration;

  return (
    <Box display="flex" flexDirection="column" sx={{ flex: 1, p: 2, pt: 0 }}>

      <NavigationConfirm condition={dirty} message="Tienes cambios sin guardar. Si sales perdarás lo que has editado ¿Estás seguro de salir?" buttonConfirm="Salir sin guardar" buttonCancel="Continuar editando" navigationType="push" onConfirm={onConfirmBack} />
      <ValidationResultDialog validations={validations} onClose={() => setValidations([])} />

      <Dialog open={confirmDeleteOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar esta propuesta de producto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancelar</Button>
          <Button onClick={onRemove} color="error">Eliminar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmReviewOpen} onClose={handleReviewCancel}>
        <DialogTitle>Confirmar envío a revisión</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas enviar esta propuesta de producto a revisión?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReviewCancel}>Cancelar</Button>
          <Button onClick={onReviewConfirmed} color="primary">Enviar</Button>
        </DialogActions>
      </Dialog>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center" sx={{ height: '58px', mb: 1 }}>
          <IconButton size="medium" onClick={onBack}><ArrowBack /></IconButton>
          {!isMobile && 
            <>
              {loading ? (
                <Box sx={{ width: '200px' }}>
                  <LinearProgress />
                </Box>
              ) : (
                <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'bold' }}>{isNew ? "Nueva propuesta de producto" : (product?.proposal?.name || "(Sin nombre)")}</Typography>
              )}
            </>}
        </Stack>

        {!!product && <Stack direction="row" spacing={2} alignItems="center">
          {status === 'PROPOSAL' && <>
            <Button variant="outlined" size="small" color="success" disabled={!canReview} onClick={handleReviewConfirm}>Enviar a revisión</Button>
            <Divider orientation="vertical" flexItem />
          </>}
          {status !== 'PRODUCT' && !product?.proposal?.ui?.new && <Button variant='outlined' color="error" size="small" onClick={handleDeleteConfirm}>Eliminar</Button>}
          {status === 'PROPOSAL' && <Button variant="contained" size="small" disabled={!dirty} onClick={onSave}>Guardar</Button>}
        </Stack>}
      </Box>

        <Box display="flex" gap={2} sx={{ overflowY: 'hidden', height: `calc(100vh - 160px)` }}>

          <Stack flex="grow" spacing={3} sx={{ overflowX: 'hidden', overflowY: 'scroll', width: '100%', p: 0 }}>
            
            <Box id="details">
              <ProposalEditor />
            </Box>
            <Box id="payment" sx={{ }}>
              <ProductAttachments readonly={readonly} files={product?.attachments} onFilesChange={onFilesChange} updatedAt={product?.updatedAt} />
            </Box>

          </Stack>

          {/* <Box id="wizard" sx={{ width: '850px' }}>
            <ProductWizard />
          </Box> */}
          {!!product && status !== 'PROPOSAL' && !loading && <Box id="chat" sx={{ width: '850px' }}>
            <Chat context="proposals" entity={params.id} provider={product?.providerId} expand={chatExpanded} onExpand={onChatExpanded} />
          </Box>}

        </Box>
    </Box> 
  );
}

function ValidationResultDialog({ validations, onClose }) { // validations is an array of validation messages

  const open = validations.length > 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Validaciones</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {validations.map((validation, idx) => (
            <Typography key={idx}>{validation}</Typography>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );

  
}


function ProductWizard() {

  const lite = true;
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Section title="ÍNDICE DE PASOS" icon={<DetailsIcon />} loading={false} expand={true} onExpand={() => {}} lite={lite}>
      <Box sx={{ p: 2 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Anterior
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      </Box>
    </Section>
  );
}


function NameStep() {

  const { product, dirty } = useStoreState((state) => state.products);
  const { updateProduct } = useStoreActions((state) => state.products);

  const onChange = (value, field) => {
    updateProduct({ ...product, [field]: value });
  }

  return (
    <Grid container direction="column">

      <Grid xs={24}>
        <Title text="NOMBRE" />
        <FormItem title="Nombre para identificar el producto" value={product?.name} onChange={v => onChange(v, "name")} />
      </Grid>
    
      <Grid xs={24}>
        <Title text="DURACIÓN" />
        <FormItem icon={<AccessTime />} title="Duración de la actividad en minutos" value={product?.duration} onChange={v => onChange(v, "duration")} />
      </Grid>

      {/* <Grid xs={24}>
          
        <Title text="OPCIONES" />
  
        <div style={{ margin: '8px', padding: '8px' }}>
          <Stack direction="column" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
            {(data?.options || []).map((option, idx) => (
              <OptionView key={idx} option={option} />
            ))}
          </Stack>
        </div>
      </Grid> */}
    </Grid>
  );
}


const steps = [
  {
    label: 'Nombre y duración',
    description: `Dale un nombre a tu producto y especifica la duración que va a tener.`,
  },
  {
    label: 'Edición del producto',
    description: `Selección del tipo de producto y edición del contenido principal. Se decribe con detalle todo lo que el cliente necesita saber sobre el producto.`,
  },
  {
    label: 'Opciones',
    description:
      'Lista de opciones que están incluídas en el producto.',
  },
  {
    label: 'Qué incluye',
    description:
      'Listado de todo lo que incluye el producto.',
  },
  {
    label: 'A tener en cuenta',
    description: `Una lista de cosas importantes que debe saber el cliente antes de realizar la actividad.`,
  },
];