import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Button, Alert, AlertTitle, Box, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled } from '@mui/styles';
import { ArrowBack, Payment as PaymentIcon, QuestionAnswer, Details as DetailsIcon, ListAlt, CalendarMonthOutlined as Schedule } from '@mui/icons-material';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Scrollchor } from 'react-scrollchor';
import { useLocation } from "react-router-dom";
import AvailabilityOverview from './AvailabilityOverview';
import Chat from '../core/Chat';
import Confirm from '../core/Confirm';
import { STATUS_ACCEPTED, STATUS_AVAILABILITY_REQUESTED, STATUS_REJECTED, STATUS_REQUESTED } from './BookingState';
import BookingsCalendar from './BookingsCalendar';
import Section from '../core/Section';
import { useMarkAsRead } from '../core/services/NotificationHook';

const AcceptButton = styled(Button)({
  background: 'green',
  color: 'white',
  transition: 'all .10s linear',
  '&:hover': {
    background: '#429741',
  },
});
const RejectButton = styled(Button)({
  background: '#E84C3D',
  color: 'white',
  transition: 'all .10s linear',
  '&:hover': {
    background: '#F76463',
  },
});

export default function AvailabilityDetails() {

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const { currentAvailability: booking, availabilityError } = useStoreState((state) => state.availabilities);
  const { fetchAvailabilityDetails, confirmAvailability, rejectAvailability } = useStoreActions((state) => state.availabilities);
  const [acceptOpened, setAcceptOpened] = useState(false);
  const [rejectOpened, setRejectOpened] = useState(false);

  const collapseAll = () => { setOverviewExpanded(false) }

  const [overviewExpanded, setOverviewExpanded] = useState(true);
  const onOverviewExpanded = (exp) => setOverviewExpanded(exp);


  const [chatExpanded, setChatExpanded] = useState(true);
  const onChatExpanded = (exp) => setChatExpanded(exp);
  const onChat = () => { setChatExpanded(true); }

  const urlParams = new URLSearchParams(location.search);

  // useMarkAsRead({ context: "bookings", subcontext: "availabilities", entity: booking?.bookingId, provider: booking?.providerId });
  useMarkAsRead({ type: 'EVENT', context: "bookings", entity: params.id, provider: booking?.providerId });

  useEffect(() => { 
    const show = urlParams.get('show');
    if (show === 'payments') {
      
      collapseAll();
    }
  }, []); 

  useEffect(() => fetchAvailabilityDetails(params.id), []);

  const onBack = () => {
    history.goBack();
  }

  const onBookingAccepted = async () => {
    onClose(true);
    await confirmAvailability(params.id);
  }

  const onBookingRejected = async () => {
    onClose(true);
    await rejectAvailability(params.id);
  }

  const onClose = async () => {
    setAcceptOpened(false);
    setRejectOpened(false);
  }

  const status = booking?.info.status;
  const confirm = booking?.confirm || false;

  return (
    <>
      {!availabilityError && booking &&
        <Grid container direction="row" width='100%' sx={{ flex: 1, p: 2, pt: 0 }}>

          <Grid xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton size="medium" onClick={onBack}><ArrowBack /></IconButton>
                {!isMobile && <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'bold' }}>{booking?.info.serviceName}</Typography>}
              </div>
            </div>
          </Grid>

          <Box id="booking-container" display="flex" gap={2} sx={{ overflowX: 'hidden', overflowY: 'scroll', height: `calc(100vh - 160px)` }}>

            <Stack flex="grow" spacing={2} sx={{ overflowX: 'hidden', overflowY: 'scroll', width: '100%' }}>

              {status === STATUS_AVAILABILITY_REQUESTED && !confirm  && 
                <Box id="approval" display="flex" gap={2} flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#FFF3E5', borderRadius: '4px', p: 2, width: '100%', height: '58px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'normal' }}>Acepta o rechaza la propuesta de disponibilidad</Typography>
                  <Stack direction="row" spacing={2}>
                    <RejectButton color="warning" variant="contained" size="small" style={{ marginLeft: '16px' }} onClick={_ => setRejectOpened(true)}>Rechazar</RejectButton>
                    <AcceptButton color="primary" variant="contained" size="small" style={{ marginLeft: '16px' }} onClick={_ => setAcceptOpened(true)}>Aceptar</AcceptButton>
                  </Stack>
                </Box>
              }

              <Box id="details">
                <AvailabilityOverview booking={booking} lite={isMobile} bookingError={availabilityError} expand={overviewExpanded} onExpand={onOverviewExpanded} />
              </Box>

              <Box id="calendar">
                <Section title="CALENDARIO" icon={<Schedule />} loading={!availabilityError && !booking} expand={true} onExpand={() => {}}>
                  <BookingsCalendar type="month" size={1} />
                </Section>
              </Box>

            </Stack>

            <Box id="chat" sx={{ width: '850px' }}>
              <Chat context="bookings" entity={params.id} provider={booking?.providerId} expand={chatExpanded} onExpand={onChatExpanded} />
            </Box>

          </Box>
        </Grid>
      }
      {!!availabilityError && 
        <Box sx={{ p: 2, pt: 4, width: '100%' }}>
          <Alert severity="error" action={
            <Button variant='contained' color="info" size="small" sx={{ m: 2 }} onClick={onBack}>
              Volver
            </Button>
          }>
            <AlertTitle>Error</AlertTitle>
            La disponibilidad solicitada no existe o ha sido eliminada
          </Alert>
        </Box>
      }

      <Confirm title="CONFIRMAR DISPONIBILIDAD" body="Acepta tu disponibilidad para este servicio" open={acceptOpened} onClose={onClose}>
        <Button onClick={onClose} variant="text">Volver</Button>
        <AcceptButton onClick={onBookingAccepted} autoFocus>Confirmar</AcceptButton>
      </Confirm>

      <Confirm title="RECHAZAR DISPONIBILIDAD" body="No tienes disponibilidad o no te interesa este servicio" open={rejectOpened} onClose={onClose}>
        <Button onClick={onClose} variant="text">Volver</Button>
        <RejectButton onClick={onBookingRejected} autoFocus>Rechazar</RejectButton>
      </Confirm>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  contentArea: {
    overflow: 'scroll',
    height: 'calc(100vh - 170px)',
  },
  buttonLabel: {
    textTransform: 'none',
  },
}));