import React from 'react';
import { useLocalStore, useStoreActions, action, thunk } from 'easy-peasy';
import { useHistory, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
// import Alert from '@mui/lab/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import authService from './services/AuthService';
import useNotifications from './services/NotificationHook';
import logoImage from './images/oyde.png';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://oyde.es/">
        OYDE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  submit: {
    marginTop: theme.spacing(5),
  },
}));

export default function Signup() {

  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { bindToken } = useNotifications();

  const setUser = useStoreActions((state) => state.auth.setUser);

  const [state, actions] = useLocalStore(() => ({
    username: "",
    password: "",
    repeatPassword: "",
    error: "", //,"El usuario o la contraseña son incorrectos.",
  
    setError: action((_state, err) => { _state.error = err }),
    setUsername: action((_state, username) => { _state.username = username; }),
    setPassword: action((_state, password) => { _state.password = password; }),
    setRepeatPassword: action((_state, repeatPassword) => { _state.repeatPassword = repeatPassword; }),

    login: thunk(async (actions, {username, password}) => {
      
      const response = await authService.login(username, password);
      if (response.succeeded) {

        setUser(response.data.access_token);
        actions.setError(); 
        return true;
      }
      actions.setError("Usuario o contraseña incorrectos");
      return false;
    }),

    signup: thunk(async (actions, {signId, social}, { getState }) => {
      const { username, password, repeatPassword } = getState();

      if (password !== repeatPassword) {
        actions.setError("Los passwords no coinciden");
        return false;
      }
      
      const response = await authService.providerSignup(signId, username, password, social);
      if (response.succeeded) {

        const succeeded = await actions.login({ username, password });
        if (succeeded) {

          await bindToken();

          // TODO: Redirect to home page...
          history.replace("/providers");
          return true;
        }
        actions.setError("Usuario o contraseña incorrectos");
        return false;
      }
      actions.setError("No se ha podido registrar el usuario. Consultar con el administrador");
      return false;
    }),
  }));


  const onSignup = async (e) => {
    e.preventDefault();

    const signId = params.signId;

    await actions.signup({ signId, social: "OYDE" });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}> */}
          {/* <LockOutlinedIcon /> */}
          <img className={classes.logo} src={logoImage} alt="Logo" width={52} height={52} />
        {/* </Avatar> */}
        <Typography component="h1" variant="h6" sx={{ mt: 1, mb: 2, color: '#1876D2', fontWeight: 'bold' }}>
          Registro de usuario
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Dirección email"
            name="email"
            autoComplete="email"
            autoFocus
            value={state.username}
            onChange={e => actions.setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Clave"
            type="password"
            id="password"
            autoComplete="current-password"
            value={state.password}
            onChange={e => actions.setPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Repite clave"
            type="password"
            id="password2"
            autoComplete="current-password"
            value={state.repeatPassword}
            onChange={e => actions.setRepeatPassword(e.target.value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recordarme"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // className={classes.submit}
            sx={{ mt: 2, mb: 3 }}
            onClick={e => onSignup(e)}
          >
            Registrar
          </Button>

          {state.error && <Alert severity="error">{state.error}</Alert>}

          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                ¿No recuerdas tu clave?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={onSignup}>
                {"Registrarse"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}