import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MessageCard from './NotificationCard';

// const MESSAGE_NEW = "MSG:NEW";

export default function NotificationList({ notifications, maxItems }) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const trimmedNotifications = (notifications || []).slice(0, maxItems || notifications.length);

  // const { notifications } = useStoreState(state => state.notifications);
  // const { fetchNotifications } = useStoreActions(actions => actions.notifications);
  // const [loading, setLoading] = useState(true);


  // const load = async () => {
  //   setLoading(true);
  //   await fetchNotifications();
  //   setLoading(false);
  // }

  // useEffect(() => {
  //   load();
  // }, []); 

  // useEffect(() => { 
  //   notifier.on(MESSAGE_NEW, notificationReceived);

  //   return function cleanup() { 
  //     notifier.off(MESSAGE_NEW, notificationReceived); 
  //   };
  // }, []);

  // const notificationReceived = useCallback(async (msg) => {
  //   console.log('MESSAGE:', msg);
  //   if (msg.ctx === "bookings") {
  //     const bookingId = Number(msg.id);
  //   }
  // });

  return (
    <Grid item container spacing={isMobile? 1 : 2} wrap="wrap" alignContent='flex-start'>
      {trimmedNotifications.map((notification, idx) => (
        <MessageCard key={idx} notification={notification} lite={isMobile} />
      ))}
    </Grid>
  );
}