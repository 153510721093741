import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Box, Button, Stack,  Divider, Badge, Typography, CircularProgress } from '@mui/material';
import { CalendarMonthOutlined as Schedule } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from "react-router-dom";
import AvailabilityCard from './AvailabilityCard';
import useNotifications from '../core/services/NotificationHook';
import { STATUS_AVAILABILITY_ACCEPTED, STATUS_AVAILABILITY_REJECTED, STATUS_AVAILABILITY_REQUESTED } from './BookingState';
import Empty from '../core/Empty';
import BookingsCalendar from './BookingsCalendar';
import BookingsScheduler from './BookingsScheduler';
import BookingsAgenda from './BookingsAgenda';
import Section from '../core/Section';

const PAGE_SIZE = 100;
const MESSAGE_NEW = "MSG:NEW";

export default function AvailabilitiesList() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const [showFilter, setShowFilter] = useState(false);

  const classes = useStyles();
  const location = useLocation();
  const { notifier } = useNotifications();
  const { availabilities } = useStoreState((state) => state.availabilities);
  const { fetchAvailabilities, emptyAvailabilityList, incrementAvailabilityNotifications } = useStoreActions((state) => state.availabilities);
  const [loading, setLoading] = useState(true);

  const urlParams = new URLSearchParams(location.search);

  const load = async () => {
    setLoading(true);
    await fetchAvailabilities();
    setLoading(false);
  }

  useEffect(() => {

    load();

    return function cleanup() {
      emptyAvailabilityList();
    }
  }, []); 

  useEffect(() => { 
    notifier.on(MESSAGE_NEW, notificationReceived);

    return function cleanup() { 
      notifier.off(MESSAGE_NEW, notificationReceived); 
    };
  }, []);

  const notificationReceived = useCallback(async (msg) => {
    console.log('MESSAGE:', msg);
    if (msg.ctx === "bookings") {
      const bookingId = Number(msg.id);
      
      incrementAvailabilityNotifications(bookingId);
    }
  });

  return (
    <Box sx={{ flex: 1, mt: 0, p: 2, pt: 0 }}>
  
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 1, pb: 3, backgroundColor: 'white' }}>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="subtitle1" style={{ color: '#5B5B5B' }}>Propuestas</Typography>
          <Divider orientation="vertical" flexItem />
          <AvailabilitiesFilter />
        </Stack>
        {/* <Button variant="contained" color="primary" onClick={onNewProduct}>Crear producto</Button> */}
      </Box>

      <Box display="flex" justifyContent="flex-start" alignItems="stretch" gap={2} sx={{ pr: 1 }}>

        <Box flex={1} sx={{ p: 1, pt: 0, pl: 0 }}>
          <Grid item container spacing={isMobile? 1 : 2} wrap="wrap" alignContent='flex-start'>
            {availabilities.map((booking, idx) => (
              <AvailabilityCard key={idx} booking={booking} lite={isMobile} />
            ))}
          </Grid>

          {!availabilities.length && !loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
            {!loading && <Empty title="No se han encontrado ítems" subtitle="Prueba a cambiar el filtro para obtener otros resultados" />}
          </Grid>}

        </Box>

        <Box display="flex" flex={1} sx={{ height: `calc(100vh - 170px)` }}>
          <Section title="RESERVAS Y DISPONIBILIDADES" icon={<Schedule />} loading={false} expand={true} onExpand={() => {}}>
            {/* <BookingsScheduler /> */}
            {/* <BookingsCalendar type="month" size={1} /> */}
            <BookingsAgenda />
          </Section>
        </Box>


      </Box>







      {/* <Box>
        <BookingsCalendar type="week" size={2} />
      </Box> */}

      {/* {availabilities.length > 0 && <Grid item container spacing={isMobile? 1 : 2} wrap="wrap" alignContent='flex-start' className={classes.contentArea}>
        {availabilities.map((booking, idx) => (
          <AvailabilityCard key={idx} booking={booking} lite={isMobile} />
        ))}
      </Grid>} */}

      {/* {!availabilities.length && !loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        {!loading && <Empty title="No se han encontrado ítems" subtitle="Prueba a cambiar el filtro para obtener otros resultados" />}
      </Grid>} */}

      {/* {loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        <CircularProgress color="inherit" />
      </Grid>} */}

    </Box>
  );
}

function AvailabilitiesFilter() {

  const { filter } = useStoreState((state) => state.availabilities);
  const { setFilter } = useStoreActions((state) => state.availabilities);

  useEffect(() => {
    if (!filter.status) {
      setFilter({ status: STATUS_AVAILABILITY_REQUESTED });
    }
  }, []); 

  return (
    <Stack direction="row" spacing={0} alignItems="center">
      <FilterButton status={STATUS_AVAILABILITY_REQUESTED} title="Pendientes" value={filter.status} onChange={s => setFilter({ ...filter, status: s ? STATUS_AVAILABILITY_REQUESTED : undefined })} />
      <FilterButton status={STATUS_AVAILABILITY_ACCEPTED} title="Aceptadas" value={filter.status} onChange={s => setFilter({ ...filter, status: s ? STATUS_AVAILABILITY_ACCEPTED : undefined })} />
      <FilterButton status={STATUS_AVAILABILITY_REJECTED} title="Rechazadas" value={filter.status} onChange={s => setFilter({ ...filter, status: s ? STATUS_AVAILABILITY_REJECTED : undefined })} />
    </Stack>
  );
}


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 0,
    border: `3px solid ${theme.palette.background.default}`,
    padding: '1px',
    color: 'white',
    backgroundColor: '#1876D2',
    fontSize: '10px',
  },
}));

function FilterButton({ status, title, value, onChange }) {
  
  const { availabilities } = useStoreState((state) => state.availabilities);
  const total = (availabilities || []).filter(a => a.info.status === status).length;

  return (
    <Button variant={status === value ? 'outlined' : 'text' } color="info" sx={{ textTransform: 'none', minWidth: '130px' }}
      onClick={() => onChange(!(status === value))}
      disabled={total === 0}
      >
      <StyledBadge badgeContent={total}>
        {title}
      </StyledBadge>
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
    // minWidth: 120,
  },
  contentArea: {
    // xs={12} sm={6} md={6} lg={2}
    overflow: 'scroll',
    [theme.breakpoints.only('xs')]: {
      height: 'calc(100vh - 120px)', 
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'calc(100vh - 290px)',
    },
    [theme.breakpoints.only('lg')]: {
      height: 'calc(100vh - 170px)',
    },
  },
}));