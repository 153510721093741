import { get, post, remove } from '../../../http';
import { store } from '../../../store';

const UNKNOWN_ERROR = { code: 0, message: 'Error desconocido', succeeded: false };

class ChatService {

  getMessages = (context, entity) => get(`/chats/${context}/${entity}`, []);
  getMetadata = (context, entities) => get(`/chats/${context}/metadata?entities=${entities.join(',') || ""}`, null);
  addMessage = (context, entity, text) => post("/chats", { context, entity, text, provider: getProvider() }, UNKNOWN_ERROR);
  confirmRead = async (context, entity) => {
    console.log('confirmRead', context, entity);
    return await get(`/chats/${context}/${entity}/metadata`, UNKNOWN_ERROR); 
  };
}

function getProvider() {
  return store.getState().auth.providerId || 0;
}

export default new ChatService();