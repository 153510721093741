import React, { useEffect, useState } from 'react';
import { Eventcalendar, localeEs } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useHistory } from "react-router-dom";
import { Box } from '@mui/material';
import bookingService from './services/BookingService';
import { STATUS_ACCEPTED, STATUS_REJECTED } from './BookingState';
import Paths from './Paths';

const OPTIONS = {
  calendar: { type: 'week' },
  agenda: {
    type: 'day',
    startDay: 1, // Monday
    endDay: 7, // Sunday
    startTime: '00:00',
    endTime: '23:59',
  }
};

export default function BookingsAgenda() {

  // const { bookings, totalBookings, bookingsFilter: filter } = useStoreState((state) => state.bookings);
  // const { fetchBookings, setBookingsFilter, resetBookingsFilter, emptyBookingList } = useStoreActions((state) => state.bookings);

  const history = useHistory();

  const [data, setData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [options, setOptions] = useState(OPTIONS);

  useEffect(() =>  {
    async function loadBookings() {

      // const filter = {text , status, confirm, fromDate, toDate, page, pageSize}
      const filter = { 
        page: 1, 
        pageSize: 1000, 
        fromDate: new Date(), 
        toDate: new Date(new Date().setMonth(new Date().getMonth() + 12))
      };

      const response = await bookingService.getBookings(filter);
      if (response.succeeded) {

        const data = response.data.items.map(b => {
          return {
            id: b._id,
            title: `${b.info.client} (${b.info.serviceName})`,
            start: new Date(b.info.startDate + 'T' + b.info.startTime),
            allDay: false,
            color: b.info.status === STATUS_ACCEPTED ? '#4caf50' : (b.info.status === STATUS_REJECTED ? '#f44336' : '#2196f3'),
            data: b
          }
        });
        // console.log(data);
        setData(data);

        if (data.length > 0) {
          setCurrentDate(data[0].start);
        }
      }
    } 
    loadBookings();
  }, []);

  const onBookingSelected = (booking) => {
    history.push(`${Paths.BOOKINGS}/${booking.bookingId}`);
  }

  return (
    // <Box display="flex" flexDirection="column" flexGrow={1} sx={{  }}>
    //   <Eventcalendar theme="material" locale={localeEs} view={options} data={data} defaultSelectedDate={new Date(2025, 2, 13)} />
    // </Box>
    <Box sx={{ height: `calc(100vh - 250px)` }}>
      <Eventcalendar theme="material" locale={localeEs} view={options} scrollable={true} 
        data={data}
        selectedDate={currentDate} 
        onSelectedDateChange={args => setCurrentDate(args.date)}
        date onEventClick={args => onBookingSelected(args.event.data)} />
    </Box>
    
  );
}


// setBookingsFilter({ ...filter, 
//   text: "",
//   status: (status != null) ? status : -1, 
//   fromDate: fromDate || "", 
//   toDate: toDate || "",
//   page: 1,
//   confirm: (confirm != null) ? Boolean(confirm) : null,
//   enabled: true,
// });