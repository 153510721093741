import { get, put, post, remove } from '../../../http';
// import { store } from '../../../store';

// const distinct = (array) => {
//   return array.filter((elem, pos, arr) => arr.indexOf(elem) == pos);
// }

class NotificationService {

  fetchNotifications = () => get("/notifications", []);
  // markNotificationAsRead = (provider, context, subcontext, entity) => put("/notifications", [{ provider, context, subcontext, entity, read: true }], null);
  markNotificationAsRead = (notificationId) => put(`/notifications/${notificationId}/read`, {}, null);
  testNotifications = () => get("/notifications/test", null);
}

// function getProvider() {
//   return store.getState().auth.providerId || 0;
// }

export default new NotificationService();