import React from 'react';
import { CssBaseline, Hidden, Drawer, Box } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { Switch, Route } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import BookingDetails from './BookingDetails';
import Bookings from './Bookings';
import Payments from './Payments';
import Products from './Products';
import Messages from './Messages';
import ProviderRegistry from './ProviderRegistry';
import ProvidersHome from './ProvidersHome';
import ProvidersMenu from './ProvidersMenu';
import { Paths } from '../../constants';



// const useStyles = makeStyles((theme) => ({
// }));

export default function Providers() {

  // const classes = useStyles();
  const collapsed = useStoreState((state) => state.menu.collapsed);
  const toggleMenu = useStoreActions((actions) => actions.menu.toggleMenu);

  return (
    <>
      <CssBaseline />
      <Box display='flex' justifyContent='start' sx={{ m: 0, p: 0 }}>
        
        {/* <Hidden smDown>
          <ProvidersMenu />
        </Hidden> */}
        {/* <div style={{ height: '85vh', width: '100%' }}> */}
        
          <Switch>
            <Route exact path={Paths.PROVIDERS}>
              <ProvidersHome />
            </Route>
            <Route path={Paths.PROVIDERS_BOOKINGS}>
              <Bookings />
            </Route>
            <Route path={Paths.PROVIDERS_AVAILABILITIES}>
              <Bookings />
            </Route>
            <Route exact path={Paths.PROVIDERS_PAYMENTS}>
              <Payments />
            </Route>
            <Route path={Paths.PROVIDERS_REGISTRY}>
              <ProviderRegistry />
            </Route>
            <Route path={Paths.PROVIDERS_PRODUCTS}>
              <Products />
            </Route>
            <Route exact path={Paths.PROVIDERS_MESSAGES}>
              <Messages />
            </Route>
          </Switch>

        {/* </div> */}
      </Box>

      {/* <Hidden mdUp>
        <Drawer anchor="left" open={!collapsed} onClose={_ => toggleMenu()}>
          <ProvidersMenu />
        </Drawer>
      </Hidden> */}
    </>
  );


  // return (
  //   <>
  //     <CssBaseline />
  //     <div style={{ display: 'flex', justifyContent: 'flex-start', margin: 0, padding: 0 }}>
        
  //       <Hidden smDown>
  //         <ProvidersMenu />
  //       </Hidden>
  //       <div style={{ height: '85vh', width: '100%' }}>
        
  //         <Switch>
  //           <Route exact path={Paths.PROVIDERS}>
  //             <ProvidersHome />
  //           </Route>
  //           <Route exact path={Paths.PROVIDERS_BOOKINGS}>
  //             <Bookings />
  //           </Route>
  //           <Route path={`${Paths.PROVIDERS_BOOKINGS}/:id`}>
  //             <BookingDetails />
  //           </Route>
  //           <Route exact path={Paths.PROVIDERS_PAYMENTS}>
  //             <Payments />
  //           </Route>
  //           <Route path={Paths.PROVIDERS_REGISTRY}>
  //             <ProviderRegistry />
  //           </Route>
  //         </Switch>

  //       </div>
  //     </div>

  //     <Hidden mdUp>
  //       <Drawer anchor="left" open={!collapsed} onClose={_ => toggleMenu()}>
  //         <ProvidersMenu />
  //       </Drawer>
  //     </Hidden>
  //   </>
  // );
}

