import { action, thunk } from 'easy-peasy';
import { formatWithOptions } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import bookingService from '../services/BookingService';
import { STATUS_AVAILABILITY_ACCEPTED, STATUS_AVAILABILITY_REJECTED } from '../BookingState';

// const dateFormatter = formatWithOptions({ locale: es }, 'yyyy-MM-dd');

export const availabilitiesStore = {

  // STATE

  availabilities: [],

  currentAvailability: null,
  availabilityError: null,

  filter: {
    status: -1,
  },

  // ACTIONS

  setAvailabilities: action((state, availabilities) => {
    state.availabilities = availabilities;
  }),
  setCurrentAvailability: action((state, booking) => {
    state.currentAvailability = booking;
  }),
  setAvailabilityError: action((state, err) => {
    state.availabilityError = err;
  }),
  setCurrentAvailabilityStatus: action((state, {status}) => {
    if (state.currentAvailability) {
      state.currentAvailability.info.status = status;
    }
  }),
  setFilter: action((state, filter) => {
    state.availabilitiesFilter = filter;
  }),
  resetFilter: action((state, _) => {
    state.filter = {
      status: -1,
    }
  }),
  emptyAvailabilityList: action((state, _) => {
    state.totalAvailabilities = 0;
    state.availabilities = [];
    state.filter.status = -1;
  }),

  incrementAvailabilityNotifications: action((state, bookingId) => {
    state.availabilities = state.availabilities.map(b => {
      if (b.bookingId === bookingId) {
        return { ...b, unreadMessages: b.unreadMessages + 1 };
      }
      return b;
    });
  }),

  // THUNKS

  fetchAvailabilities: thunk(async (actions) => {

    const response = await bookingService.getAvailabilities();
    if (response.succeeded) {

      actions.setAvailabilities(response.data);
    }
    else {
      actions.setAvailabilities([]);
      // TODO: muestra error en pantalla...
      console.log(response.message)
    }
  }),

  fetchAvailabilityDetails: thunk(async (actions, payload) => {

    actions.setCurrentAvailability(null);

    const response = await bookingService.getAvailabilityDetails(payload);
    if (response.succeeded) {
      actions.setCurrentAvailability(response.data);
      actions.setAvailabilityError();
    }
    else {
      actions.setCurrentAvailability(null);
      actions.setAvailabilityError(response.message);
    }
  }),

  confirmAvailability: thunk(async (actions, availabilityId) => {

    const response = await bookingService.confirmAvailability(availabilityId);
    if (response.succeeded) {
      actions.setCurrentAvailabilityStatus(STATUS_AVAILABILITY_ACCEPTED);
      return true;
    }
    console.log(response.message)
    return false;
  }),

  rejectAvailability: thunk(async (actions, availabilityId) => {
    
    const response = await bookingService.rejectAvailability(availabilityId);
    if (response.succeeded) {
      actions.setCurrentAvailabilityStatus(STATUS_AVAILABILITY_REJECTED);
      return true;
    }
    console.log(response.message)
    return false;
  }),


};