import React, { useMemo } from 'react';
import { Grid, Typography, Chip, Badge, Tooltip, Card, CardActionArea } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccessTime, NotificationsActiveOutlined, PeopleAlt, ChatBubbleOutline, FlightOutlined, PaymentOutlined as PaymentIcon, AccountCircle, CalendarMonthOutlined as Schedule } from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import { useStoreState } from 'easy-peasy';
import { formatWithOptions, isSameDay, isSameYear } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import { Paths } from '../../constants';
import BookingState, { getBookingStateColor, getBookingStateBackgroundColor, STATUS_AVAILABILITY_REQUESTED } from './BookingState';

// export const STATUS_NOT_ASSIGNED = 0;
// export const STATUS_ASSIGNED = 1;
// export const STATUS_REQUESTED = 4;
// export const STATUS_ACCEPTED = 6;
// export const STATUS_REJECTED = 7;
// export const STATUS_RESERVED = 2;
// export const STATUS_CANCELLED = 3;

const dateWithoutYearFormatter = formatWithOptions({ locale: es }, 'd LLLL');
const dateWithYearFormatter = formatWithOptions({ locale: es }, 'd LLLL yyyy');
const dayOfWeekFormatter = formatWithOptions({ locale: es }, 'EEEE');
const timeFormatter = formatWithOptions({ locale: es }, 'HH:mm');

export default function AvailabilityCard({booking, lite}) {

  const { availabilityNotifications } = useStoreState(state => state.notifications);
  const badged = useMemo(() => availabilityNotifications.find(n => n.entity === booking.bookingId), [availabilityNotifications, booking.bookingId]);

  const history = useHistory();
  const classes = useStyles();
  const stateColor = getBookingStateColor(booking.info);
  const stateBgColor = getBookingStateBackgroundColor(booking.info);

  const onOpen = (bookingId) => {
    history.push(`${Paths.PROVIDERS_AVAILABILITIES}/${bookingId}`);
  };

  const peopleText = booking.info.pax === 1 ? "persona" : "personas";

  const { startDate, startTime } = booking.info;

  const fromDate = new Date(`${startDate}`);
  const now = new Date();
  
  const dateFormatter = isSameYear(fromDate, now) ? dateWithoutYearFormatter : dateWithYearFormatter;
  const dateComponent = (<span>{dateFormatter(fromDate)} - <strong>{dayOfWeekFormatter(fromDate)}</strong></span>);

  const actionRequired = booking.info.status == STATUS_AVAILABILITY_REQUESTED;

  return (
    <Grid item xs={12} lg={12}>
      <Card className={classes.item} elevation={2} style={{ border: '1px solid #DBD7D2', borderLeft: '8px solid gray', borderLeftColor: stateColor }}>
        <CardActionArea onClick={_ => onOpen(booking.bookingId)}>

          <div className={classes.card}>

            {/* <div className={classes.cardLine} style={{ backgroundColor: stateColor }}></div> */}
            <div className={classes.cardContent}>

              <div style={{ maxWidth: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle1" color="primary" style={{ marginLeft: '8px', fontWeight: 'bold' }}>{booking.info.serviceName}</Typography>
                <Badge variant="standard" color="error" sx={{ mr: 3 }} badgeContent={1} invisible={!badged}></Badge>
              </div>
              
              <div style={{ display: 'flex' }}>
                <Chip className={classes.label} variant="outlined" icon={<AccountCircle />} label={booking.info.client?.toUpperCase() || "(Sin información de cliente)"} sx={{ border: 0 }} />
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Chip className={classes.label} variant="outlined" icon={<Schedule />} label={dateComponent} sx={{ border: 0 }} />
                <Chip className={classes.label} variant="outlined" icon={<PeopleAlt />} label={`${booking.info.pax} ${peopleText}`} sx={{ border: 0 }} />
              </div>

              <div style={{ display: 'flex' }}>
                {/* <Chip className={classes.label} variant="outlined" icon={<AccessTime />} label={booking.info.availability || "(Sin información)"} sx={{ border: 0 }} /> */}
                <Chip style={{ margin: '10px 10px 0px 5px' }}
                  variant="default" 
                  // color="secondary"
                  size="small"
                  icon={<AccessTime />}
                  // avatar={<Avatar>!</Avatar>}
                  label={booking.info.availability || "(Sin información)"}
                />
              </div>

              <div style={{ backgroundColor: stateBgColor, marginTop: '16px', width: '100%', height: '34px', borderRadius: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>

                {/* <Button size="small" style={{ marginLeft: '8px' }} onClick={e => { e.stopPropagation() }}>test</Button> */}
                <BookingState booking={booking} />
                {/* <div style={{ display: 'flex', paddingLeft: '8px' }}>
                  <Cancel fontSize="small" style={{ color: '#E84C3D' }} />
                  <Typography variant="body2" style={{ color: '#5B5B5B', fontWeight: 'bold', marginLeft: '8px' }}>CANCELADA</Typography>
                </div> */}
                
                <div style={{ display: 'flex', paddingLeft: '8px' }}>

                  {actionRequired && <BootstrapTooltip title="Pendiente de confirmar por parte del proveedor">
                    <div size="small" style={{ marginRight: '15px' }}><NotificationsActiveOutlined color="secondary" /></div>
                  </BootstrapTooltip>}

                  {booking.totalMessages > 0 && <BootstrapTooltip title={`Chat con mensajes${booking.unreadMessages > 0 ? " pendientes de leer" : ""}`}>
                    <div size="small" style={{ marginRight: '15px' }}><Badge badgeContent={booking.unreadMessages} variant="standard" color="error"><ChatBubbleOutline color="primary" /></Badge></div>
                  </BootstrapTooltip>}
                  
                </div>
              </div>

            </div>
          </div>

        </ CardActionArea>
      </Card>
    </Grid>
  );
};


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer',
    padding: '8px', 
    transition: 'all .20s linear',
    boxSshadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
    '&:hover' : {
      boxShadow: '-1px 1px 9px 0px rgba(0,0,0,0.4)',
    },
    // '&$itemHeader': {
    //   '&:hover' : {
    //     backgroundColor: '#dfe7fd',
    //   },
    // },
  },
  itemHeader: {
    backgroundColor: '#F5F5F5', 
    borderRadius: '5px 5px 0px 0px',
    borderBottom: '1px solid white',

    transition: 'all .20s linear',
    '&:hover' : {
      backgroundColor: '#dfe7fd', // faf3dd
      // backgroundColor: '#faf3dd',
    },
  },
  link: {
    marginRight: '10px',
    // color: 'darkorange',
  },
  logo: {
    height: '34px',
    margin: '10px',
  },

  card: {
    display: 'flex',
  },
  cardLine: {
    width: '8px',
    // backgroundColor: '#E84C3D',
    backgroundColor: 'lightgray',
    borderRadius: '2px',
  },
  cardContent: {
    width: '100%',
    padding: 0, //padding: '0 0 0 8px',
    // paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  label: {
    // borderRadius: '3px',
    border: '0px',
    marginRight: '8px',
    // color: '#5B5B5B',
    maxWidth: '100%',
  },
  tag: {
    borderRadius: '3px',
    // border: '0px',
    marginRight: '8px',
    color: '#5B5B5B',
  }
}));