import { action, thunk, computed } from 'easy-peasy';
import { formatWithOptions } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import notificationService from '../services/NotificationService';

// const dateFormatter = formatWithOptions({ locale: es }, 'yyyy-MM-dd');

export const notificationStore = {

  // STATE

  notifications: [],

  unreadNotifications: computed((state) => state.notifications.filter(n => !n.read)),
  messageNotifications: computed((state) => state.notifications.filter(n => n.type === 'MESSAGE' && !n.read)),
  bookingNotifications: computed((state) => state.notifications.filter(n => n.type === 'EVENT' && n.context === "bookings" && !n.read)),
  reservationNotifications: computed((state) => state.notifications.filter(n => n.type === 'EVENT' && n.context === "bookings" && n.subcontext === 'reservations' && !n.read)),
  availabilityNotifications: computed((state) => state.notifications.filter(n => n.type === 'EVENT' && n.context === "bookings" && n.subcontext === 'availabilities' && !n.read)),
  productNotifications: computed((state) => state.notifications.filter(n => n.type === 'EVENT' && n.context === "products" && !n.read)),


  // ACTIONS

  setNotifications: action((state, notifications) => {
    state.notifications = notifications;
  }),


  // THUNKS

  fetchNotifications: thunk(async (actions) => {

    // actions.setNotifications(FAKE_NOTIFICATIONS);

    console.log('fetching notifications');

    const response = await notificationService.fetchNotifications();
    const notifications = response.succeeded ? response.data : [];

    actions.setNotifications(notifications);
    return notifications;
  }),


  markNotificationAsRead: thunk(async (actions, notificationId, { getState }) => {
    const notifications = getState().notifications;
    const notification = notifications.find(n => n._id === notificationId);
    if (notification) {

      console.log('marking as read:', notification);

      await notificationService.markNotificationAsRead(notificationId);

      actions.setNotifications(notifications.map(n => {
        if (n._id === notificationId) {
          return { ...n, read: true };
        }
        return n;
      }));
    }
  }),
  // markNotificationAsRead: thunk(async (actions, { context, subcontext, entity, provider }, { getState }) => {
  //   const notifications = getState().notifications;
  //   const notification = notifications.find(n => n.context === context && n.subcontext === subcontext && n.entity === entity && n.provider === provider && !n.read);
  //   if (notification) {

  //     // console.log('marking as read:', notification);

  //     await notificationService.markNotificationAsRead(provider, context, subcontext, entity);

  //     actions.setNotifications(notifications.map(n => {
  //       if (n.context === context && n.subcontext === subcontext && n.entity === entity && n.provider === provider) {
  //         return { ...n, read: true };
  //       }
  //       return n;
  //     }));
  //   }
  // }),
};

// interface INotification {
//   type: string
//   provider: number
//   entity: number
//   context: string
//   title: string
//   text: string
//   timestamp: Date
//   read: boolean
//   link: string
// }

const FAKE_NOTIFICATIONS = [
  {
    type: "EVENT", // MESSAGE, EVENT
    provider: 89,
    entity: 118738,
    context: "bookings",
    title: "Nueva reserva",
    text: "Tienes una nueva reserva pendiente de confirmación. Tienes una nueva reserva pendiente de confirmación. Tienes una nueva reserva pendiente de confirmación.",
    timestamp: new Date(),
    read: false,
    link: "/providers/bookings/118733",
  },
  {
    type: "MESSAGE", // MESSAGE, EVENT
    provider: 89,
    entity: 118738,
    context: "bookings",
    title: "Nuevo mensaje",
    text: "Tienes un nuevo mensaje en una reserva",
    timestamp: new Date(),
    read: false,
    link: "/providers/bookings/118733",
  },
  {
    type: "MESSAGE", // MESSAGE, EVENT
    provider: 89,
    entity: 118738,
    context: "bookings",
    title: "Nuevo mensaje",
    text: "Tienes un nuevo mensaje en una reserva",
    timestamp: new Date(),
    read: true,
    link: "/providers/bookings/118733",
  }
];