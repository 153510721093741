import React, { useEffect, useState, useCallback } from 'react';
import { Grid, IconButton, Button, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Tooltip, Typography, CircularProgress, Pagination } from '@mui/material';
import { Cancel, Search, Tune } from '@mui/icons-material';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from "react-router-dom";
import BookingCard from './BookingCard';
import useNotifications from '../core/services/NotificationHook';
import { STATUS_LIST } from './BookingState';
import Empty from '../core/Empty';

const PAGE_SIZE = 100;
const MESSAGE_NEW = "MSG:NEW";

export default function BookingsList() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const [showFilter, setShowFilter] = useState(false);

  const classes = useStyles();
  const location = useLocation();
  const { notifier } = useNotifications();
  const { bookings, totalBookings, bookingsFilter: filter } = useStoreState((state) => state.bookings);
  const { fetchBookings, setBookingsFilter, resetBookingsFilter, emptyBookingList, incrementBookingNotifications } = useStoreActions((state) => state.bookings);
  const [loading, setLoading] = useState(true);

  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    const hasParams = urlParams.toString() !== "";
    if (hasParams) {

      initializeFiltersFromUrlParams();
    }
    else {
      enableFilters();
    }

    return function cleanup() {
      emptyBookingList();
    }
  }, []); 

  useEffect(() => { if (filter.enabled) onSearch(); }, [filter]);

  useEffect(() => { 
    notifier.on(MESSAGE_NEW, notificationReceived);

    return function cleanup() { 
      notifier.off(MESSAGE_NEW, notificationReceived); 
    };
  }, []);

  const onSearch = async () => {
    setLoading(true);
    await fetchBookings({ filter, pageSize: PAGE_SIZE });
    setLoading(false);
  }

  const initializeFilters = () => {
    resetBookingsFilter();
  }

  const enableFilters = () => {
    setBookingsFilter({ ...filter, enabled: true });
  }

  const initializeFiltersFromUrlParams = () => {
    const status = urlParams.get('status');
    const fromDate = urlParams.get('fromDate');
    const toDate = urlParams.get('toDate');
    const confirm = urlParams.get('confirm');

    setBookingsFilter({ ...filter, 
      text: "",
      status: (status != null) ? status : -1, 
      fromDate: fromDate || "", 
      toDate: toDate || "",
      page: 1,
      confirm: (confirm != null) ? Boolean(confirm) : null,
      enabled: true,
    });
  }

  const notificationReceived = useCallback(async (msg) => {
    console.log('MESSAGE:', msg);
    if (msg.ctx === "bookings") {
      const bookingId = Number(msg.id);
      
      incrementBookingNotifications(bookingId);
    }
  });

  return (
    <Grid container direction="column" sx={{ flex: 1, mt: 0, p: 2, pt: 0 }}>
      
      <Grid item container direction="row" justify="space-between" alignItems='center' style={{ marginBottom: '10px' }}>

        <Grid item>
          {isMobile && !showFilter && <Button variant="text" color="primary" startIcon={<Tune />} onClick={_ => setShowFilter(true)}>Filtros</Button>}
          {isMobile && showFilter && <Button variant="text" color="secondary" onClick={_ => setShowFilter(false)}>Ocultar filtros</Button>}
        </Grid>

        <Grid item>
          {isMobile && totalBookings > 0 && <Pagination page={filter.page} count={Math.ceil(totalBookings / PAGE_SIZE)} onChange={(e, page) => setBookingsFilter({ ...filter, page })} shape="rounded" />}
        </Grid>
      </Grid>
     
      
      {((showFilter && isMobile) || !isMobile) && <Grid item container justify="flex-start" alignItems="flex-end" spacing={2} style={{ padding: 0, marginBottom: '24px' }}>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextField
            variant='standard'
            label="Buscar"
            placeholder="Filtro texto"
            value={filter.text}
            onChange={e => setBookingsFilter({ ...filter, text: e.target.value, page: 1 })}
            onKeyPress={(ev) => { if (ev.key === 'Enter') onSearch(); }}
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton size="small" onClick={onSearch}><Search /></IconButton>
              </InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <FormControl className={classes.formControl}>
            <InputLabel id="booking-filter-status-label">Estado</InputLabel>
            <Select
              variant='standard'
              labelId="booking-filter-status-label"
              id="booking-filter-status"
              placeholder="Selecciona..."
              value={filter.status}
              onChange={e => setBookingsFilter({ ...filter, status: e.target.value, page: 1 })}
            >
              {STATUS_LIST.map((v, i) => (<MenuItem key={i} value={v.id}>{v.name}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextField
            variant='standard'
            label="Fecha Inicio"
            type="date"
            fullWidth={true}
            value={filter.fromDate}
            onChange={e => setBookingsFilter({ ...filter, fromDate: e.target.value, page: 1 })}
            // className={classes.textField}
            placeholder={"fecha"}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextField
            variant='standard'
            label="Fecha Final"
            type="date"
            fullWidth={true}
            value={filter.toDate}
            onChange={e => setBookingsFilter({ ...filter, toDate: e.target.value, page: 1 })}
            // className={classes.textField}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item>
          <Tooltip title="Quitar filtros">
            {/* <Button startIcon={<Clear />} variant="text" onClick={initializeFilters}>Limpiar filtro</Button> */}
            <IconButton size="small" color="default" onClick={initializeFilters}><Cancel /></IconButton>
          </Tooltip>
        </Grid>

        {!isMobile && totalBookings > 0 && <Grid container item xs justify="flex-end">
          <Pagination page={filter.page} count={Math.ceil(totalBookings / PAGE_SIZE)} onChange={(e, page) => setBookingsFilter({ ...filter, page })} shape="rounded" />
        </Grid>}
        
      </Grid>}
    
      {/* {totalBookings > 0 && <Grid item container>
        <div className={classes.contentArea} style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}>
        {bookings.map((booking, idx) => (
          <BookingCard key={idx} booking={booking} lite={isMobile} />
        ))}
        </div>
      </Grid>} */}
      {totalBookings > 0 && <Grid item container spacing={isMobile? 1 : 2} wrap="wrap" alignContent='flex-start' sx={{ p: 1, pt: 0, pl: 0 }}>
        {bookings.map((booking, idx) => (
          <BookingCard key={idx} booking={booking} lite={isMobile} />
        ))}
      </Grid>}

      {!totalBookings && !loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        {/* <Typography variant="h5" color="primary">Sin resultados</Typography> */}
        {!loading && <Empty title="No se han encontrado ítems" subtitle="Prueba a cambiar el filtro para obtener otros resultados" />}
      </Grid>}

      {loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        <CircularProgress color="inherit" />
      </Grid>}

    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
    // minWidth: 120,
  },
  contentArea: {
    // xs={12} sm={6} md={6} lg={2}
    overflow: 'scroll',
    [theme.breakpoints.only('xs')]: {
      height: 'calc(100vh - 120px)', 
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'calc(100vh - 290px)',
    },
    [theme.breakpoints.only('lg')]: {
      height: 'calc(100vh - 170px)',
    },
  },
}));