import React, { useEffect } from 'react';
// import classnames from 'classnames';
// import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles } from '@mui/styles';
import { Grid, Box, Stack, Typography, TextField, InputAdornment, Chip, Avatar, Alert } from '@mui/material';
// import { Alert } from '@mui/lab';
import AccessTime from '@mui/icons-material/AccessTime';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Place, MeetingRoom, LabelOutlined, Comment as CommentIcon, List as ListIcon, FlightTakeoff, FlightLand, ConfirmationNumber, AccountCircle, ListAlt as DetailsIcon, Height, CalendarMonthOutlined as Schedule } from '@mui/icons-material';
import { formatWithOptions, isSameDay, isSameYear } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import HTMLRenderer from 'react-html-renderer'
import BookingState from './BookingState';
import Section from '../core/Section';
import { Title, DetailItem } from '../core/Forms';
import { TagList } from './ProposalEditor';


const LOC_ORIGIN = ["", "UBICACIÓN", "DESPLAZAMIENTO", "ORIGEN"];
const LOC_DEST = ["", "", "", "DESTINO"];
const useStyles = makeStyles(STYLES);

const dateWithYearFormatter = formatWithOptions({ locale: es }, 'EEEE, d LLLL yyyy');
// const dayOfWeekFormatter = formatWithOptions({ locale: es }, 'EEEE');

const AvailabilityOverview = ({booking, lite, bookingError, expand, onExpand}) => {

  const classes = useStyles();

  const info = booking?.info;
  const peopleText = info?.pax === 1 ? "persona" : "personas";
  
  let fromDate = "";
  if (info?.startDate) { 
    fromDate = dateWithYearFormatter(new Date(info?.startDate));
  }

  return (
    <Section title="PETICIÓN DISPONIBILIDAD" icon={<DetailsIcon />} loading={!bookingError && !booking} expand={expand} onExpand={onExpand}
      extra={<BookingState booking={booking} />} lite={lite}>

      {bookingError ? 
        <Alert severity="error">{bookingError}</Alert> :

        <Grid container direction="column">
          {/* <Title text="GENERAL" /> */}

          {lite && <Grid container item direction="row">
            <Grid item xs={12}>
              <DetailItem icon={<LabelOutlined />} title="Servicio" content={info?.serviceName} />
            </Grid>
          </Grid>}

          <Grid container item direction="row" alignItems="flex-end" sx={{ ml: 1, mr: 1 }}>
            <Grid item xs={12}>
              <DetailItem icon={<Schedule />} title="Inicio servicio" content={fromDate} />
            </Grid>
          </Grid>

          <Grid container item direction="row" alignItems="flex-end" sx={{ ml: 1, mr: 1 }}>
            <Grid item xs={12}>
              <DetailItem icon={<AccessTime />} title="Detalle" content={info?.availability} />
            </Grid>
          </Grid>

          
          {/* <Grid item direction="row">
            <Stack direction="row" spacing={3}>
              {info?.client && 
                <DetailItem icon={<AccountCircle />} title="Cliente" content={info?.client} />
              }
              <DetailItem icon={<PeopleAlt />} title="Número de personas" content={`${info?.pax} ${peopleText}`} />
            </Stack>
          </Grid> */}

          <Grid container item direction="row" sx={{ ml: 1, mr: 1, mt: 1 }}>
            {info?.client && <Grid item md={8} xs={12} sx={{ backgroundColor: '#F8F8F8', borderRadius: '4px' }}>
              <DetailItem icon={<AccountCircle />} title="Cliente" content={info?.client} />
            </Grid>}

            <Grid item md={info?.client ? 4 : 12} xs={12} sx={{ pl: info?.client ? 2 : 0 }}>
              <DetailItem icon={<PeopleAlt />} title="Número de personas" content={`${info?.pax} ${peopleText}`} />
            </Grid>
          </Grid>
          
        </Grid>
      }
    </Section>
  );
}

export default AvailabilityOverview;




function STYLES(theme) {
  return {
    characteristics: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  };
}