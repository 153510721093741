import { get, post, put, remove } from '../../../http';
import { STATUS_AVAILABILITY_ACCEPTED, STATUS_AVAILABILITY_REJECTED } from '../BookingState';


class BookingService {

  getBookings = ({text, status, confirm, fromDate, toDate, page, pageSize}) => { 
    const uri = `/bookings?filter=${text || ""}&status=${status || ""}&confirm=${confirm != null ? confirm : ""}&fromDate=${fromDate || ""}&toDate=${toDate || ""}&page=${page || 1}&pageSize=${pageSize || 10000}&updated=${Date.now()}`;
    // console.log(uri)
    return get(uri, []);
  }
  getBookingsOverview = () => get(`/bookings/overview?&updated=${Date.now()}`, null);
  getBookingDetails = (id) => get(`/bookings/${id}`, null);
  getAvailabilities = () =>  get(`/availabilities`, []); 
  getAvailabilityDetails = (id) => get(`/availabilities/${id}`, null);
  getBookingPayment = (id) => get(`/bookings/${id}/payments`, null);
  getBookingMessages = (id) => get(`/bookings/${id}/messages`, []);
  updateBookingStatus = (id, status) => post(`/bookings/${id}/status`, { status }, null);
  confirmAvailability = (id) => post(`/availabilities/${id}/status`, { status: STATUS_AVAILABILITY_ACCEPTED }, null);
  rejectAvailability = (id) => post(`/availabilities/${id}/status`, { status: STATUS_AVAILABILITY_REJECTED }, null);
  confirmBooking = (id) => get(`/bookings/${id}/confirm`, null);
  rejectBookingPayment = (id, reason) => put(`/bookings/${id}/payments`, { rejected: true, reason }, null);
  getPayments = ({status, fromDate, toDate}) => { 
    const uri = `/bookings/payments?status=${status || ""}&fromDate=${fromDate || ""}&toDate=${toDate || ""}`;
    return get(uri, []);
  }
}

export default new BookingService();